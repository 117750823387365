<template>
  <div class="border max-w-md bg-white cursor-pointer" @click="$emit('onClick')" :class="customClass">
    <div class="p-4 lg:p-6">
      <div class="flex space-x-5">
        <Icons :name="icon" size="10" color="black"/>
        <div class="">
          <Text size="xs" color="black" :content="category"/>
          <Text size="2xl" weight="bold" color="black" :content="count.toString()"/>
        </div>
      </div>
    </div>
    <div class="bg-gray-100 py-2 border-t px-4 lg:px-6">
      <Text @click="() => $emit('onViewAll')" size="sm" color="primary-600" content="View all" decoration="underline" custom-class="cursor-pointer"/>
    </div>
  </div>
</template>

<script>
import Icons from "../../../atoms/Icons/Icons.vue";
import Text from '../../../atoms/Text/Text'

export default {
  components: {
    Icons,
		Text
  },
  props: {
    icon: {
      type: String,
      default: ''
    },
    category: {
      type: String,
      default: ''
    },
    count: {
      type: Number,
      default: 0
    }
  },
};
</script>


<style>
</style>
