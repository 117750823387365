<template>
    <div class="px-2 sm:px-10 py-4">
        <div class="flex space-x-5 items-start">
            <div
                v-if="company?.avatar"
                class="w-20 h-20"
                :class="company?.avatar ? '' : 'bg-gray-200'"
            >
                <img
                    :src="company?.avatar"
                    class="w-full h-full object-cover"
                />
            </div>
            <div class="flex space-x-5 items-center">
                <Text
                    color="black"
                    weight="bold"
                    size="xl"
                    class="sm:text-3xl"
                    :content="get(company, 'name')"
                />
                <Text
                    size="sm"
                    color="primary-600"
                    content="edit"
                    decoration="underline"
                    custom-class="cursor-pointer"
                />
            </div>
        </div>
        <div class="grid grid-cols-1 md:grid-cols-3 gap-5 mt-5">
            <CardWithIconAndButton
                v-for="card in [
                    {
                        icon: 'LightBulbIcon',
                        category: 'Ventures',
                        count: getTeams(),
                    },
                    {
                        icon: 'UsersIcon',
                        category: 'People',
                        count: getUsers(),
                    },
                    {
                        icon: 'ExclamationIcon',
                        category: 'Opportunities',
                        count: getOpportunities(),
                    },
                ]"
                :key="card.category"
                v-bind="card"
                @onViewAll="() => onViewAll(card.category)"
            />
        </div>
        <div class="border px-6 py-4 bg-white mt-5">
            <Text
                size="sm"
                weight="semibold"
                color="black"
                content="Invite founders Startup Virginia!"
            />
            <Text
                custom-class="mt-4"
                size="xs"
                color="black"
                content="You can email this link to people that you'd like to join so that you help them through each step with their idea and track their progress."
            />
            <div class="mt-4">
                <Text
                    size="xs"
                    weight="semibold"
                    color="black"
                    content="Registration Link"
                />
                <div
                    class="border rounded-md border-gray-300 relative px-3 py-2 flex justify-between items-center mt-1"
                >
                    <input
                        class="border-none outline-none focus:ring-0 focus:border-none focus:outline-none w-full"
                        v-model="copyUrl"
                        autocomplete="off"
                    />
                    <Icon
                        name="DocumentDuplicateIcon"
                        size="6"
                        color="gray-500"
                        custom-class="cursor-pointer"
                        @click="() => onCopyUrl(copyUrl)"
                    />
                </div>
            </div>
        </div>
        <div class="mt-10">
            <ListViewHeader :title="'Current Cohort'" />
            <div class="space-y-3 mt-5">
                <div v-if="cohorts && cohorts.length">
                    <CardWithBadge
                        v-for="cohort in cohorts"
                        :key="cohort.id"
                        v-bind="cohort"
                        @onClick="
                            () =>
                                $router.push({
                                    name: 'Ecosystem Cohort Detail',
                                    params: {
                                        companyId: company?.id,
                                        id: cohort?.id,
                                    },
                                })
                        "
                    />
                </div>
                <EmptyStateSimple
                    v-else
                    class=""
                    isFull
                    :description="'No records available!'"
                />
            </div>
        </div>
        <div class="mt-10">
            <ListViewHeader
                :title="'Programs'"
                :btn-text="'Add'"
                @onClick="() => (isOpenAddProgram = true)"
            />
            <div class="space-y-3 mt-5">
                <div v-if="programs && programs.length">
                    <CardWithBadge
                        v-for="cohort in programs"
                        :key="cohort.id"
                        v-bind="cohort"
                        :onClick="
                            () => {
                                $router.push({
                                    name: 'Ecosystem Program Detail',
                                    params: {
                                        id: cohort?.id,
                                        companyId: company?.id,
                                    },
                                });
                            }
                        "
                    />
                </div>
                <EmptyStateSimple
                    v-else
                    class=""
                    isFull
                    :description="'No records available!'"
                />
            </div>
        </div>
        <!-- <AddProgram
            v-bind="{
                ...c.add_program,
                isShow: isOpenAddProgram,
                isLoading,
            }"
            @onClose="() => (isOpenAddProgram = false)"
        /> -->
        <WithNoSwiper
            v-bind="{
                isShow: isOpenAddProgram,
                isLoading: false,
                isSaving: isSavingProgram,
                list: form,
                modelKey: 'modelField',
                title: form[0].title,
                content: form[0].content,
            }"
            :saveForm="saveForm"
            @onClose="
                () => {
                    isOpenAddProgram = false;
                }
            "
        />
        <WelcomeModal
            :open="isShow"
            v-bind="welcome"
            @onClose="(isOpen) => onCloseWelcome(isOpen)"
        />
        <!-- <WelcomeCohortModal :open="true"
                title="Welcome to The Idea Factory - we're glad you're here!"
                textHtml="Which of your ideas would you like to use as part of The Idea Factory?"
                text="Sharing an idea with The Idea Factory will allow the admins to be able to see and edit your data so they can help you navigate each step of the process."
        /> -->
        <!-- <EcosystemAcceptModal
            :open="true"
            title="Welcome to Startup Virginia - we're glad you're here!"
            textHtml="Which of your ideas would you like to share with Startup Virginia?"
            text="Sharing an idea with Startup Virginia will allow their admins to be able to see your data so they can help you navigate the next steps of your idea."
        /> -->
    </div>
</template>

<script>
import EmptyState from "../components/organisms/EmptyState/Range/Range";
import EmptyStateSimple from "@/components/organisms/EmptyState/Simple/Simple";
import ListViewHeader from "../components/molecules/ListViewHeader/WithTitleAndButton/WithTitleAndButton";
import CardWithBadge from "../components/molecules/CardListItem/CardWithBadge/CardWithBadge";
import CardWithImage from "../components/molecules/CardListItem/CardWithImage/CardWithImage";
import Table from "../components/organisms/Tables/Simple/Simple.vue";
import Text from "../components/atoms/Text/Text";
import { c } from "../components/constants.js";
import InviteMember from "../components/organisms/Modals/InviteMember/InviteMember.vue";
import Icon from "../components/atoms/Icons/Icons";
import CardWithIconAndButton from "../components/molecules/CardListItem/CardWithIconAndButton/CardWithIconAndButton";
import { get, upperFirst } from "lodash";
import AddProgram from "@/components/organisms/Modals/AddProgram/AddProgram";
import WelcomeModal from "../components/organisms/Modals/WelcomeEcosystemModal/WelcomeEcosystemModal";
// import WelcomeCohortModal from "@/components/organisms/Modals/WelcomeCohortModal/WelcomeCohortModal";
import EcosystemAcceptModal from "@/components/organisms/Modals/EcosystemAcceptModal/EcosystemAcceptModal";
import WithNoSwiper from "@/components/organisms/Modals/WithNoSwiper/WithNoSwiper";
import { programForm } from "../../.storybook/sampleData";
import { Settings } from "../../settings";
import moment from "moment";
import useClipboard from "vue-clipboard3";

export default {
    components: {
        AddProgram,
        WelcomeModal,
        EmptyState,
        ListViewHeader,
        CardWithImage,
        CardWithBadge,
        Text,
        InviteMember,
        Table,
        Icon,
        CardWithIconAndButton,
        WithNoSwiper,
        EmptyStateSimple,
        // WelcomeCohortModal,
        EcosystemAcceptModal,
    },
    data() {
        return {
            content: c.ecosystem,
            isShow: false,
            isOpenTip: true,
            isLoading: false,
            get,
            c,
            company: null,
            cohorts: [],
            programs: [],
            isOpenAddProgram: false,
            welcome: c.welcomeEcosystem,
            copyUrl: null,
            form: programForm,
            isSavingProgram: false,
        };
    },
    methods: {
        getOpportunities() {
            let items = 0;
            this.company?.programs?.map((p) =>
                p?.cohorts?.map((c) =>
                    c?.ventures?.map((v) => {
                        items += v?.opportunities?.filter(
                            (o) =>
                                !o?.isArchived &&
                                o?.surveyOpportunities?.find(
                                    (s) => s?.responses?.length > 0
                                )
                        )?.length;
                    })
                )
            );
            return items;
        },
        async onCopyUrl(url) {
            const { toClipboard } = useClipboard();
            try {
                await toClipboard(url);
                this.actions.alert.showSuccess({
                    message: "Copied the url to your clipboard",
                });
            } catch (e) {
                console.error(e);
            }
        },
        getTeams() {
            let ventures = [];
            ventures = this.company?.ventures;
            this.company?.programs?.map((p) =>
                p?.cohorts?.map(
                    (c) =>
                        (ventures = [
                            ...ventures,
                            ...c?.ventures?.filter(
                                (v) => !ventures?.find((vv) => vv?.id === v?.id)
                            ),
                        ])
                )
            );
            return ventures?.length;
        },
        getUsers() {
            const people = [];
            this.company?.ventures?.map((v) =>
                v?.users?.map((u) => {
                    if (!people?.find((p) => p?.id?.id === u?.id)) {
                        people?.push({
                            Name:
                                upperFirst(u?.firstName) +
                                " " +
                                upperFirst(u?.lastName),
                            Email: u?.email,
                            Team: upperFirst(v?.name),
                            "Date Joined": moment(u?.createdAt).format(
                                "M/D/YY"
                            ),
                            id: u,
                        });
                    }
                })
            );
            this.company?.programs?.map((p) =>
                p?.cohorts?.map((c) =>
                    c?.ventures?.map((v) =>
                        v?.users?.map((u) => {
                            if (!people?.find((p) => p?.id?.id === u?.id)) {
                                people?.push({
                                    Name:
                                        upperFirst(u?.firstName) +
                                        " " +
                                        upperFirst(u?.lastName),
                                    Email: u?.email,
                                    Team: upperFirst(v?.name),
                                    "Date Joined": moment(u?.createdAt).format(
                                        "M/D/YY"
                                    ),
                                    id: u,
                                });
                            }
                        })
                    )
                )
            );
            return people?.length;
        },
        async onCloseWelcome(isOpen) {
            try {
                this.isShow = false;
                await this.actions.user.saveUser({
                    where: { id: this.state.currentUser?.id },
                    data: {
                        metadata: {
                            ...this.state.currentUser?.metadata,
                            firstLogin: false,
                        },
                    },
                });
                // this.isShowIdea = isOpen;
                // let query = Object.assign({}, this.$route.query);
                // delete query.registered;
                // this.$router.push("/");
            } catch (e) {
                console.log(e);
            }
        },
        onViewAll(item) {
            if (item === "Venture Teams") {
                this.$router.push({
                    name: "Ecosystem Venture Teams",
                    params: { companyId: this.company?.id },
                });
            } else if (item === "People") {
                this.$router.push({
                    name: "Ecosystem People",
                    params: { companyId: this.company?.id },
                });
            } else if (item === "Opportunities") {
                this.$router.push({
                    name: "Ecosystem Opportunities",
                    params: { companyId: this.company?.id },
                });
            }
        },
        async saveForm(data) {
            console.log(data, "data");
            this.isSavingProgram = true;
            const params = {
                name: data?.name,
                company: { connect: { id: this.company?.id } },
            };
            if (data.type) params["type"] = data?.type?.name;
            if (data.admin?.id)
                params["admins"] = { connect: { id: data.admin.id } };

            if (data.duration) params["duration"] = parseFloat(data?.duration);
            const response = await this.actions.saveFormData({
                mutation: "saveProgram",
                params,
            });
            if (response && response.id) {
                this.programs?.push({
                    title: response?.name,
                    actionLabel: response?.type,
                    id: response?.id,
                    subtitles: [
                        {
                            icon: "CalendarIcon",
                            name: "Cohorts",
                            count: 0,
                        },
                        {
                            icon: "LightBulbIcon",
                            name: "Ventures",
                            count: 0,
                        },
                        {
                            icon: "UsersIcon",
                            name: "Team",
                            count: 0,
                        },
                        {
                            icon: "ExclamationIcon",
                            name: "Opportunities",
                            count: 0,
                        },
                    ],
                });
                const cmpny = {
                    ...this.company,
                    programs: [...this.company.programs, response],
                };
                this.actions.setCurrentCompany(cmpny);
            }
            if (data?.admin?.label) {
                await this.actions.user.inviteContact({
                    contacts: [{ email: data?.admin?.label }],
                    userId: this.state.currentUser?.id,
                    metadata: {
                        programId: response?.id,
                        companyId: this.$route.params?.companyId,
                    },
                });
            }
            this.isSavingProgram = false;
            this.isOpenAddProgram = false;
        },
    },
    async created() {
        // this.isShow = this.state.currentUser?.metadata?.firstLogin;
        if (this.state.currentCompany?.id === this.$route.params?.companyId) {
            this.company = this.state.currentCompany;
        } else {
            const companies = await this.actions.company.getCompanies({
                where: { id: this.$route.params?.companyId },
                getValues: true,
                query: 'companyDefault'
            });
            this.company = companies?.[0];
            this.actions.setCurrentCompany(this.company);
        }
        console.log(this.company, "company");
        this.copyUrl = `https://${Settings.mainDomain}/#/register-user?eco=${this.$route.params?.companyId}`;
        this.company?.programs?.map((p) => {
            let ventures = 0,
                team = 0,
                pOpportunities = 0;
            p.cohorts
                ?.filter(
                    (c) =>
                        moment(c?.endDate).format("YYYY/MM/DD h:mA") >
                        moment().format("YYYY/MM/DD h:mA")
                )
                .map((c) => {
                    let opportunities = 0,
                        people = 0;
                    c?.ventures?.map((v) => {
                        people += v?.users?.length || 0;
                        opportunities +=
                            v?.opportunities?.filter(
                                (o) =>
                                    !o?.isArchived &&
                                    o?.surveyOpportunities?.find(
                                        (s) => s?.responses?.length > 0
                                    )
                            )?.length || 0;
                    });
                    this.cohorts.push({
                        title: c.name,
                        actionLabel: `${moment(c?.startDate).format(
                            "MM/DD"
                        )} - ${moment(c?.endDate).format("MM/DD")}`,
                        id: c?.id,
                        subtitles: [
                            {
                                icon: "LightBulbIcon",
                                name: "Ventures",
                                count: c?.ventures?.length || 0,
                            },
                            {
                                icon: "UsersIcon",
                                name: "People",
                                count: people,
                            },
                            {
                                icon: "ExclamationIcon",
                                name: "Opportunities",
                                count: opportunities,
                            },
                        ],
                    });
                });

            p.cohorts.map((c) => {
                ventures += c?.ventures?.length;
                let people = 0;
                c?.ventures?.map((v) => {
                    people += v?.users?.length || 0;
                });
                team += people;
            });

            p?.cohorts?.map((c) => {
                let opportunities = 0;
                c?.ventures?.map((v) => {
                    opportunities +=
                        v?.opportunities?.filter(
                            (o) =>
                                !o?.isArchived &&
                                o?.surveyOpportunities?.find(
                                    (s) => s?.responses?.length > 0
                                )
                        )?.length || 0;
                });
                pOpportunities += opportunities;
            });
            this.programs?.push({
                title: p?.name,
                actionLabel: p?.type,
                id: p?.id,
                subtitles: [
                    {
                        icon: "CalendarIcon",
                        name: "Cohorts",
                        count: p?.cohorts?.length || 0,
                    },
                    {
                        icon: "LightBulbIcon",
                        name: "Ventures",
                        count: ventures,
                    },
                    {
                        icon: "UsersIcon",
                        name: "People",
                        count: team,
                    },
                    {
                        icon: "ExclamationIcon",
                        name: "Opportunities",
                        count: pOpportunities,
                    },
                ],
            });
        });
        // if (this.company?.users?.length > 0) {
        //     this.form[0].fields[3].options = JSON.stringify(
        //         this.company.users.map((user) => {
        //             return { id: user.id, name: user.fullName };
        //         })
        //     );
        // } else {
        //     this.form[0].fields[3].options = JSON.stringify([]);
        // }
    },
};
</script>
